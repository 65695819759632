@import './src/styles/grid';

.profile-form {
  .user-profile-data {
    border-top: 1px solid #f1f1f1;
    display: flex;
    padding: 22px 55px 31px 55px;

    .left {
      margin-right: 23px;
      flex: 1;
    }

    .right {
      flex: 1;
    }

    .ant-form-item-row {
      flex-direction: column;

      .ant-form-item-label {
        text-align: left;
      }
    }
  }

  .user-profile-lang,
  .user-profile-password {
    align-items: center;
    border-top: 1px solid #f1f1f1;
    display: flex;
    padding: 35px 55px 30px 55px;

    h2 {
      flex: 1;
      margin-right: 23px;
    }

    .fieldWrapper {
      flex: 1;
    }

    div {
      margin-bottom: 0;
    }
  }

  .user-profile-password button {
    flex: 1;
    margin-left: 23px;
  }

  .buttonWrapper {
    padding-left: 55px;
    padding-right: 55px;
    margin-bottom: 24px;
  }
}
