@import '../../styles/grid';

.layoutLoginWrapper {
  background-image: url('../../../public/images/login_bg.png');
  background-repeat: no-repeat;
  background-position: right 0;
  height: 100vh;
}

.layoutLogin {
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: $md) {
    width: $md;
  }

  @media screen and (min-width: $lg) {
    width: $lg;
  }

  @media screen and (min-width: $xl) {
    width: $xl;
  }

  @media screen and (min-width: $xxl) {
    width: $xxl;
  }

  .layoutForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 150px;

    @media screen and (min-width: $lg) {
      width: 50%;
      padding-right: 2%;
    }

    .logo {
      background: #fff;
      padding-top: 40px;
      width: 100%;

      @media screen and (min-width: $xl) {
        background: transparent;
      }

      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
}
