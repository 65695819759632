.restaurantForm {
  .hidden {
    display: none;
  }

  .tabFormMenu {
    flex: 1 100%;
    margin: 18px 0 61px 0;

    .tabFormMenuWrapper {
      display: table;
      margin: 0 auto;
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;

    button[type='submit'] {
      margin-left: 22px;
    }
  }

  .ant-radio-button-wrapper.red {
    background-color: red;
    color: #fff;
  }

  .languageTab {
    width: 100%;

    p {
      text-align: center;
    }

    .selectedLanguage {
      display: flex;
      justify-content: center;

      .defaultImg {
        align-items: center;
        border: 1px solid rgba(241, 241, 241, 1);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        height: 40px;
        margin: 0 5px 28px 5px;
        width: 40px;
        position: relative;

        button {
          position: absolute;
          top: -5px;
          right: -5px;
          min-width: 16px;
          width: 16px;
          height: 16px;

          span {
            font-size: 10px;
            justify-content: center;
            display: flex;
            margin-top: -2px;
          }
        }
      }
    }

    .langFieldWrapper {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      width: 25%;

      .ant-form-item {
        width: 100%;
      }
    }
  }
}
