.dishForm {
  .dishCheckboxWrapper {
    display: flex;
    justify-content: center;

    .checkboxDish {
      border: 1px solid #d9d9d9;
      height: 50px;
      padding-left: 10px;
      width: 88px;
    }

    .checkboxmeal {
      background-image: url('../../../public/images/icons_dish.png');
      background-position: 37px -62px;
      background-repeat: no-repeat;
      border-radius: 8px 0 0 8px;
      margin: 0;

      &.ant-radio-wrapper-checked {
        background-position: 37px 9px;
        border-color: #1890ff;
      }
    }

    .checkboxdrink {
      background-image: url('../../../public/images/icons_dish.png');
      background-position: -47px 10px;
      background-repeat: no-repeat;
      border-radius: 0 8px 8px 0;

      &.ant-radio-wrapper-checked {
        background-position: -47px -61px;
        border-color: #1890ff;
      }
    }
  }
  .dishFormContent {
    display: flex;
    flex-flow: row wrap;
    margin-top: 54px;
  }

  .leftForm,
  .rightForm {
    flex: 1 0 0;
  }

  .rightForm {
    margin-left: 2%;
  }

  .row {
    width: 50%;
  }

  .fieldWrapper {
    .ant-form-item-label {
      text-align: left;
      width: 100%;
    }
  }

  .dishFormDetails {
    display: flex;
    flex-flow: row wrap;
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;

    button[type='submit'] {
      margin-left: 22px;
    }
  }
}
