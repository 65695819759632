.multi-lang-tab {
  line-height: 1;

  .ant-tabs-nav {
    margin-bottom: 2px;

    .ant-tabs-tab {
      font-size: 22px;
      margin-left: 5px;
    }
  }
}
