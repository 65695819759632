@import 'src/styles/grid';

.login-form {
  width: 100%;

  form {
    background: #fff;
    padding: 70px 10% 30px 10%;
    width: 100%;

    @media screen and (min-width: $xl) {
      background: transparent;
      width: 80%;
    }
  }

  .link {
    color: #1677ff;
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
  }
}
