.dishDetails {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 5%);
  display: inline-block;
  margin-bottom: 20px;
  padding: 0 0 34px;
  width: 100%;

  .image {
    width: 100%;

    img {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      max-width: 100%;
    }
  }

  .dishDetailsCategory {
    color: #a5a5a5;
    font-size: 14px;
    padding: 28px 40px 0 40px;
  }

  .dishDetailsContent {
    padding: 28px 40px 40px 40px;

    .dishName {
      font-size: 22px;
      font-weight: 400;
      line-height: 22px;
      margin-top: 0;
    }
  }

  .detailsInfoWrapper,
  .dishTypeWrapper {
    padding-top: 24px;

    .detailsWrapperBox {
      text-align: center;
      padding-bottom: 8px;
    }

    p {
      margin: 0 0 9px 0;
    }

    span {
      margin: 0;
    }

    .tagWrapper {
      padding: 7px 35px;

      .tagWrapperImg {
        margin-left: 8px;
      }

      span {
        align-items: center;
        display: flex;
      }
    }
  }

  .ingredientsWrapper,
  .allergensWrapper,
  .detailsInfoWrapper,
  .dishTypeWrapper {
    border-top: 1px solid #f1f1f1;
    padding-bottom: 30px;

    .ingredients,
    .allergens {
      padding: 0 40px;

      span {
        margin-bottom: 10px;
      }
    }

    .name {
      display: flex;
      justify-content: center;

      p {
        background-color: #fff;
        margin-top: -12px;
        padding: 0 29px;
        text-align: center;
      }
    }
  }
}
