@import './src/styles/grid';

.resetPasswordForm {
  width: 100%;

  form {
    background: #fff;
    box-sizing: border-box;
    padding: 60px 50px 30px 50px;
    width: 100%;

    @media screen and (min-width: $xl) {
      background: transparent;
    }
  }

  .link {
    color: #1677ff;
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
  }

  .informMessage {
    background: #fff;
    box-sizing: border-box;
    padding: 30px 50px;
    width: 100%;
  }
}
