.dishListWrapper {
  display: flex;
  flex-direction: column;
  width: 22%;

  .leftMenuWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .leftMenu {
      width: 100%;
    }

    .leftFull {
      height: auto;
      margin-top: 20px;
    }
  }
}

.leftMenuWrapper {
  .dishIcon {
    height: 19px;
  }
}
