.page-404 {
  margin: 33px 0 30px 0;
  text-align: center;
  width: 100%;

  h1 {
    margin-top: -20px;
  }

  img {
    display: block;
    margin: 0 auto;
  }
}
