@import '../../styles/grid';

.appContainer {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $xl) {
    margin: 0 5%;
  }

  .listContainer {
    display: flex;
    flex-direction: row;
    gap: 0 1%;

    @media screen and (min-width: $xl) {
      gap: 0 2%;
    }
  }
}
