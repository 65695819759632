@import '../../styles/grid';
.leftMenuWrapper .leftMenu .leftMenuContent ul li.profile {
  box-sizing: border-box;
  align-items: center;
  display: flex;
  padding: 0;
  width: 100%;

  a {
    color: #5e5e5e;
    display: block;
    padding: 10px 20px 11px 34px;
    width: 100%;
    text-decoration: none;
  }

  button {
    margin-right: 19px;

    img {
      display: inline-block;
    }
  }
}

.settingsContainer {
  display: flex;
  gap: 0 1%;

  @media screen and (min-width: $xl) {
    gap: 0 2%;
  }

  .page {
    width: 75%;
  }
}
