body {
  font-family: 'Roboto', serif;
  background-color: #f7f7f7;
  box-sizing: border-box;
  margin: 0;
  font-style: normal;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 22px;
}

h1 {
  font-family: Roboto, serif;
  font-size: 33px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}

h2 {
  font-family: Roboto, serif;
  font-size: 23px;
  font-weight: 400;
  line-height: 22px;
}

.page {
  background-color: #fff;
  border-radius: 10px;
  padding: 56px 0 0 0;
}

.pageHeader {
  padding: 0 0 30px 55px;
}

.buttonWrapper {
  padding-top: 30px;
}
