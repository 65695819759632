.headerModal {
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-family: 'Roboto';
    font-size: 33px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 16px;
  }
}
