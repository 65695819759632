.userForm {
  padding-top: 20px;
  .userIcon {
    background-color: #fff;
    display: block;
    margin: -10px auto 0 auto;
    width: 32px;

    svg {
      font-size: 32px;
    }

    img {
      display: block;
      height: 31px;
      margin: 0 auto;
      width: 29px;
    }
  }

  .userFormWrapper {
    display: flex;
    flex-flow: row wrap;
    margin-top: 32px;
  }

  .leftForm,
  .rightForm {
    flex: 1 0 0;
  }

  .rightForm {
    margin-left: 2%;
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;

    button[type='submit'] {
      margin-left: 22px;
    }
  }
}
