@import 'src/styles/grid';

.registration-form {
  width: 100%;

  form {
    background: #fff;
    padding: 60px 50px 30px 50px;
    width: 100%;

    @media screen and (min-width: $xl) {
      background: transparent;
    }
  }

  .link {
    color: #1677ff;
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
  }
}

.registrationMessageWrapper {
  padding: 30px 50px;
  width: 75%;

  .registrationMessage {
    align-items: center;
    display: flex;
  }

  .message-icon {
    color: green;
    display: inline-block;
    font-size: 32px;
    margin-right: 15px;
  }
}
