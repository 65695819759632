@import 'src/styles/grid';

.activatePage {
  width: 100%;

  .activatePageBg {
    background: #fff;
    padding: 60px 50px 30px 50px;

    @media screen and (min-width: $xl) {
      background: transparent;
    }
  }

  .success-icon {
    color: green;
    display: inline-block;
    font-size: 32px;
    margin-right: 15px;
  }

  .error-icon {
    color: red;
    display: inline-block;
    font-size: 32px;
    margin-right: 15px;
  }

  .link {
    color: #1677ff;
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
  }
}
