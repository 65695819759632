header {
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  position: static;
  top: 0;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 10px 10px;
  margin-bottom: 20px;

  .logo {
    color: #333333;
    font-size: 22px;
    text-decoration: none;
  }

  .menu {
    align-items: center;
    display: flex;

    p {
      margin: 0 10px 0 0;
    }

    .buttonLanguage {
      margin-right: 10px;

      img {
        margin-top: 3px;
      }
    }

    .avatarButton {
      background-color: #bfbfbf;
      color: #fff;

      &:hover,
      &:focus {
        border-color: #bfbfbf;
      }
    }
  }
}
