.pageContent {
  border-top: 1px solid #f1f1f1;
  padding-top: 60px;
}
.subscriptions {
  display: flex;
  margin: 0;
  padding: 0;

  li {
    border-left: 1px solid #f1f1f1;
    list-style-type: none;
    flex: 1 1 0;
    padding: 0 21px;

    &:first-child {
      border-left: none;
    }

    &.active {
      .subscriptionTileWrapper {
        border: 3px solid #00d32f;
        position: relative;

        .subscriptionTile {
          border: 1px solid transparent;
        }
      }

      .subscriptionIcon {
        color: #00d32f;
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0 auto -23px auto;
        right: 0;
        width: 33px;

        svg {
          background-color: #fff;
          height: 33px;
          width: 33px;
        }
      }
    }

    .subscriptionTileWrapper {
      border: 3px solid transparent;
      border-radius: 10px;
      cursor: pointer;
      margin-bottom: 24px;

      .subscriptionTile {
        border: 1px solid #f1f1f1;
        border-radius: 10px;
        padding: 49px 31px 50px 49px;
        text-align: center;

        .subscriptionTileInternal {
          border: 2px solid transparent;
        }

        &:hover {
          border: 3px solid #00d32f;

          .subscriptionTileInternal {
            border: none;
          }
        }

        p {
          color: rgba(94, 94, 94, 0.5);
          font-family: Roboto;
          font-size: 14px;
          line-height: 19px;
          margin: 0 0 25px 0;
        }

        .title {
          color: #333333;
          font-family: Roboto;
          font-size: 23px;
          font-weight: 500;
          line-height: 22px;
          margin: 0 0 29px 0;
        }

        .price {
          color: #333333;
          font-family: Roboto;
          font-size: 46px;
          font-weight: 800;
          margin-bottom: 4px;

          span {
            font-size: 18px;
            font-weight: 400;
          }
        }

        button {
          width: auto;
        }
      }
    }

    .text {
      font-size: 14px;
      line-height: 19px;
      font-weight: 300;
      text-align: center;

      b {
        display: block;
        font-weight: 700;
      }
    }

    .subscriptionsSelect {
      margin-bottom: 9px;
      width: 100%;
    }

    button {
      width: 100%;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
