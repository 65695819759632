@import '../../styles/grid';

.wrapper {
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: $md) {
    width: $md;
  }

  @media screen and (min-width: $lg) {
    width: $lg;
  }

  @media screen and (min-width: $xl) {
    width: $xl;
  }

  @media screen and (min-width: $xxl) {
    width: $xxl;
  }
}
