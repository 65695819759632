.modalPackage {
  .ant-modal-title {
    font-family: Roboto;
    font-size: 33px;
    font-weight: 400;
    line-height: 22px;
    margin: 40px 0;
    text-align: center;
  }
}

.packageList {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 15px;

      svg {
        margin-right: 15px;
      }

      .green {
        svg {
          color: #00d32f;
        }
      }

      .grey {
        svg {
          color: #c2c2c2;
        }
      }
    }
  }

  .cancelButton {
    display: block;
    margin: 40px auto 0 auto;
  }
}
