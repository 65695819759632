@import 'src/styles/grid';

.leftMenuWrapper {
  width: 25%;

  @media screen and (min-width: $xl) {
    width: 24%;
  }

  .leftMenu {
    background-color: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
    display: inline-block;
    height: 100%;
    padding: 0 0 0 0;
    width: 100%;
    transition: all 0.2s ease-out;

    &.shortLeftMenu {
      width: 100px;
      transition: all 0.2s ease-out;

      .leftMenuHeader {
        justify-content: center;
        padding: 0 0 26px 0;
      }

      .leftMenuHeaderText {
        display: none;
      }

      .leftMenuFirst p {
        display: none;
      }

      .leftMenuFooter .leftMenuFooterText {
        display: none;
      }
    }

    .hide {
      display: none !important;
    }

    .showElement {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.85);
      position: absolute;
      top: -16px;
      left: 33px;
    }

    .hideElement {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.85);
      position: absolute;
      top: -16px;
      left: 44%;
    }

    .leftMenuHeader {
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #f1f1f1;
      display: flex;
      padding: 24px 20px 22px 34px;

      @media screen and (min-width: $md) {
        justify-content: left;
      }

      p {
        display: none;
        @media screen and (min-width: $md) {
          margin: 0 0 0 14px;
          display: inline-block;
        }
      }

      .anticon svg {
        font-size: 20px;
      }
    }

    .leftMenuContent {
      border-bottom: 1px solid #f1f1f1;
      padding: 20px 0;
      position: relative;

      .menuText {
        cursor: pointer;
        width: 100%;

        span {
          align-items: center;
          display: flex;
          gap: 0 10px;
        }
      }

      .ant-spin {
        display: block;
        margin: 0 auto;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          align-items: center;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          padding: 10px 5% 11px 5%;
          position: relative;

          @media screen and (min-width: $xl) {
            padding: 10px 5% 11px 10%;
          }

          &:hover,
          &.active {
            background-color: #f7f7f7;
          }

          &:hover {
            .leftMenuButtons {
              background-color: #f7f7f7;
              display: block;
              position: absolute;
              right: 0;
            }
          }

          &.active:after {
            display: inline-block;
            content: '';
            height: 27px;
            width: 20px;
            background-image: url('../../../public/images/arrow_active.png');
            position: absolute;
            right: -9px;
            background-size: cover;
          }

          .imageWrapper {
            align-items: center;
            border: 1px solid #dddddd;
            border-radius: 50%;
            height: 32px;
            width: 32px;
            overflow: hidden;

            .image {
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              height: 100%;
              width: 100%;
            }
          }

          .avatarColor {
            background-color: #ffd8bf;
            color: #fff;
            text-transform: uppercase;
          }

          .leftMenuText {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .leftMenuButtons {
            display: none;

            &.show {
              transition: all 0.2s ease-out;
            }
          }
        }
      }

      .leftMenuFirst {
        align-items: center;
        display: flex;
        flex-direction: column;

        p {
          margin: 23px 0 0 0;
        }
      }
    }

    .leftMenuFooter {
      display: flex;
      justify-content: center;
      padding: 20px 7% 34px 7%;

      .leftMenuFooterText {
        display: none;

        @media screen and (min-width: $md) {
          display: inline-block;
        }

        .leftMenuFooterTextName {
          display: none;

          @media screen and (min-width: $xl) {
            display: inline-block;
          }
        }
      }
    }
  }
}

.leftMenuMore {
  display: flex;
  flex-flow: column;

  .leftMenu {
    margin-bottom: 20px;
  }
}
