.colorWrapper {
  .ant-form-item {
    margin-bottom: 4px;
  }
}

.backgroundColorWrapper {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 100%;

  .backgroundColor {
    border-radius: 5px;
    cursor: pointer;
    height: 22px;
    margin: 5px;
  }
}

.backgroundColorPopover {
  position: absolute;
  z-index: 2;
}

.backgroundColorCover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
