.changeLanguageButton {
  margin-right: 10px;
}

.wrapperFlag {
  display: flex;
  align-content: center;
  align-items: center;

  img {
    margin-right: 6px;
  }
}
