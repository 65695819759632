.menuForm {
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;

    button[type='submit'] {
      margin-left: 22px;
    }
  }
}
